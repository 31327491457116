import React from "react";
import { useStaticQuery, graphql, PageProps, Link } from "gatsby";
import Symbol from "../assets/images/symbol.inline.svg";
import { SectionProps } from "./section";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export type NewsProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.NewsQuery>;

const News: React.FC<NewsProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.NewsQuery>(
    graphql`
      query News {
        allContentfulLayoutNews {
          edges {
            node {
              id
              heading
              callToAction
              callToActionUrl
              newsItems {
                id
                title
                date(formatString: "YYYY.MM.DD")
                url
                category
                thumbnail {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    `
  );

  const content = data.allContentfulLayoutNews.edges.find(
    edge => edge.node.id === contentModuleId
  );

  return (
    <section id="news" className="news">
      <div className="section px-0">
        <div className="news__content container">
          <div
            className="section__title-container"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            <h2 className="section__title news__title">
              {content.node.heading}
            </h2>
            <Symbol
              className="section__symbol"
              alt="医療機器管理システム HITOTSU ロゴ横棒"
              loading="lazy"
            />
          </div>
          {content.node.newsItems.length > 0 && (
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={30}
              slidesPerView={3}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              className="news__swiper"
              breakpoints={{
                1: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {content.node.newsItems.map((newsItem, index) => {
                const thumbnailNode: any = newsItem.thumbnail;
                const thumbnail = getImage(thumbnailNode);
                return (
                  <SwiperSlide id={`slide_${index}`} key={newsItem.id}>
                    <Link
                      to={newsItem.url}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                    >
                      <div className="newsItem__container">
                        <GatsbyImage
                          image={thumbnail}
                          alt={newsItem.title}
                          loading="lazy"
                          objectFit="contain"
                          className="newsItem__thumbnail"
                        />
                        <div className="newsItem__text-container">
                          <div className="flex justify-between">
                            <div className="newsItem__category">
                              {newsItem.category}
                            </div>
                            <div>{newsItem.date}</div>
                          </div>
                          <div className="newsItem__title">
                            {newsItem.title}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          <div className="flex justify-center mt-8 md:mt-12 2xl:mt-16">
            <a
              href={content.node.callToActionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="btn btn--primary rounded-full"
                data-sal="fade"
              >
                {content.node.callToAction}
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
