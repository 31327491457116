import React, {  useState } from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Modal from "../components/contactForm";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { SectionProps } from "./section";

type HeroProps = Partial<SectionProps> & PageProps<GatsbyTypes.HeroQuery>;

const Hero: React.FC<HeroProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.HeroQuery>(graphql`
    query Hero {
      allContentfulLayoutHero {
        edges {
          node {
            id
            heading {
              heading
            }
            subheading
            ctaText
            ctaUrl
            ceAppeal
            image {
              fluid(quality: 85) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      desktopImage: file(relativePath: { eq: "hero_back.png" }) {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
      smartphoneImage: file(relativePath: { eq: "hero_back_smartphone.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1000)
        }
      }
    }
  `);

  const content = data.allContentfulLayoutHero.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const desktopImage = data.desktopImage.childImageSharp.gatsbyImageData;
  const smartphoneImage = data.smartphoneImage.childImageSharp.gatsbyImageData;

  const imageDesktop = getImage(desktopImage);
  const imageSmartphone = getImage(smartphoneImage);

  const bgImageDesktop = convertToBgImage(imageDesktop);
  const bgImageSmartphone = convertToBgImage(imageSmartphone);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BackgroundImage
        Tag="div"
        {...(typeof window !== "undefined" && window.innerWidth < 768
          ? bgImageSmartphone
          : bgImageDesktop)}
        preserveStackingContext
        className="absolute bg-cover"
      >
        <section className="hero section mx-auto pb-40 md:pb-60 md:py-48 lg:py-60">
          <div className="hero__tagline">
            <div className="hero__tagline-content-wrap">
              <h1 className="hero__tagline_container">
                <span
                  className="hero__tagline-subtitle"
                  data-sal="fade"
                  data-sal-delay="100"
                >
                  全国の
                  <br />
                  <span className="hero__tagline-linear">臨床工学技士の声</span>
                  が
                  <br />
                  <span className="hero__border-bottom">
                    集まり日々進化する
                  </span>
                </span>
              </h1>
              <h2 className="hero__tagline-title" data-sal="fade">
                {content.node.heading.heading}{" "}
                <span className="hero__tagline-title__hitotsu">HITOTSU</span>
              </h2>
              <div>
                <button
                  id="modal__btn-interest0"
                  className="btn btn--primary rounded-full hero__btn"
                  type="button"
                  data-sal="fade"
                  onClick={openModal}
                >
                  フリートライアルを申込む
                </button>
                <Modal
                  modalIsOpen={modalIsOpen}
                  closeModal={() => {
                    closeModal();
                  }}
                  interestNum={"interest0"}
                />
                <button
                  id="modal__btn-interest1"
                  className="btn btn--primary rounded-full  hero__btn"
                  style={{ marginTop: 24 }}
                  type="button"
                  data-sal="fade"
                  onClick={openModal}
                >
                  資料を請求する
                </button>
                <Modal
                  modalIsOpen={modalIsOpen}
                  closeModal={() => {
                    closeModal();
                  }}
                  interestNum={"interest1"}
                />
              </div>
            </div>
          </div>
          <div
            className="hero__image"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-duration="500"
          >
            <div className="hero__image-container">
              <div className="hero__circle">
                <span className="hero__circle-text">
                  {content.node.ceAppeal}
                </span>
              </div>
              <img
                src={content.node.image.fluid.src}
                className="m-auto"
                alt="全国の臨床工学技士の声が集まり日々進化する 医療機器管理システム HITOTSU イメージ"
                width={320}
                height={268}
              />
            </div>
          </div>
        </section>
      </BackgroundImage>
    </>
  );
};

export default Hero;
