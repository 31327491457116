import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, PageProps, Link } from "gatsby";
import Symbol from "../assets/images/symbol.inline.svg";
import { SectionProps } from "./section";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

type TestimonialsProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.TestimonialsQuery>;

const Testimonials: React.FC<TestimonialsProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.TestimonialsQuery>(graphql`
    query Testimonials {
      allContentfulLayoutTestimonials {
        edges {
          node {
            id
            heading
            callToAction
            testimonialItems {
              id
              nameSp {
                nameSp
              }
              namePc {
                namePc
              }
              description {
                description
              }
              image {
                gatsbyImageData(quality: 25, placeholder: NONE, height: 350)
              }
              url
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutTestimonials.edges.find(
    edge => edge.node.id === contentModuleId
  );

  return (
    <section id="testimonials" className="testimonials section px-0 max-w-full">
      <div className="w-full text-center">
        <div
          className="section__title-container"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
        >
          <h2 className="section__title testimonial__title">
            {content.node.heading}
          </h2>
          <Symbol
            className="section__symbol"
            alt="医療機器管理システム HITOTSU ロゴ横棒"
            loading="lazy"
          />
        </div>
        {content.node.testimonialItems.length > 0 && (
          <Swiper
            modules={[Pagination, Navigation]}
            spaceBetween={15}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="testimonial__swiper"
            breakpoints={{
              1: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 64,
              },
              1280: {
                slidesPerView: 1,
                spaceBetween: 120,
              },
            }}
          >
            {content.node.testimonialItems.map((item, index) => {
              const ImageNode: any = item.image;
              const Image = getImage(ImageNode);
              return (
                <SwiperSlide id={`slide_${index}`} key={item.id}>
                  <Link
                    to={item.url}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                  >
                    <div className="testimonial-item__container p-4">
                      <GatsbyImage
                        image={Image}
                        alt={`医療機器管理システム HITOTSU 導入事例 画像${index}`}
                        loading="lazy"
                        objectFit="contain"
                        className="testimonial-item__thumbnail"
                      />
                      <div className="testimonial-item__text-container">
                        <div className="quatation-mark">“</div>
                        <div className="testimonial-item__description">
                          {item.description.description}
                        </div>
                        <div className="testimonial-item__name md:hidden">
                          {item.nameSp.nameSp}
                        </div>
                        <div className="testimonial-item__name testimonial-item__namePc hidden md:flex">
                          {item.namePc.namePc}
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        <div className="flex justify-center mt-8 md:mt-12 2xl:mt-16">
          <a
            href="https://note.com/hitotsu_life/m/mc9b7c618796e"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              className="btn btn--primary rounded-full"
              data-sal="fade"
            >
              {content.node.callToAction}
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
