import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Symbol from "../assets/images/symbol.inline.svg";
import AboutItem from "../components/aboutItem";
import { SectionProps } from "./section";

type AboutProps = Partial<SectionProps> & PageProps<GatsbyTypes.AboutQuery>;

const About: React.FC<AboutProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.AboutQuery>(graphql`
    query About {
      allContentfulLayoutAbout {
        edges {
          node {
            id
            heading
            description {
              description
            }
            featureItem {
              id
              title {
                title
              }
              titleThreeLine {
                titleThreeLine
              }
              image {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  width: 400
                  height: 200
                )
              }
              pointNumber
            }
            image {
              gatsbyImageData(quality: 50, placeholder: NONE)
            }
            callToAction
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutAbout.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const imageNode: any = content.node.image;
  const image = getImage(imageNode);

  return (
    <section id="about" className="about-us relative -mt-28 md:mt-0">
      <div className="section">
        <div className="about-us__content 2xl:-mt-20">
          <div className="about__top flex flex-col md:flex-row mt-28 sm:mt-48 md:mt-0">
            <div className="about__top-right flex-1">
              <GatsbyImage
                image={image}
                alt={"医療機器管理システム HITOTSU HITOTSUとは"}
                className="about-us__image rounded-r-10 absolute left-0 -top-8 max-h-52 sm:max-h-60 md:max-h-72 xl:max-h-96 w-3/5 md:w-1/2"
                loading="lazy"
                objectFit="cover"
              />
            </div>
            <div className="about__top-right flex-1 mt-12 md:mt-0">
              <div
                className="section__title-container md:relative md:ml-6 xl:ml-10 2xl:ml-16"
                data-sal="fade"
                data-sal-easing="ease-in-cubic"
              >
                <h2 className="section__title about-us-section__title">
                  「
                  <span className="hitotsu-text about__hitotsu-text">
                    HITOTSU
                  </span>
                  」とは？
                </h2>
                <Symbol
                  className="section__symbol about__section-symbol"
                  alt="医療機器管理システム HITOTSU ロゴ横棒"
                  loading="lazy"
                />
              </div>
              <h3
                className="about__description my-6 md:w-full md:mb-24 md:ml-6 md:pl-4 md:text-left xl:ml-10 xl:-mt-8 xl:mb-32 2xl:ml-16"
                data-sal="slide-up"
                data-sal-easing="ease-in-cubic"
                data-sal-delay="100"
              >
                {content.node.description.description}
              </h3>
            </div>
          </div>
          {content.node.featureItem.length > 0 && (
            <div className="about__aboutItem-container md:mx-0 mt-8 md:mt-10">
              <h2 className="text-lg text-center mb-3 md:flex md:flex-col md:m-6 md:text-2xl lg:flex-row 2xl:m-8">
                <span className="flex">
                  <span className="about__round-cap-bottom">
                    「医療機器安全のサポーター」
                  </span>
                  として
                </span>
                寄り添うシステム
              </h2>
              <div className="md:flex md:flex-row">
                {content.node.featureItem.map(feature => (
                  <AboutItem feature={feature} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default About;
