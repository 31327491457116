import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type AboutItemProps = {
  feature: {
    id: string;
    title: {
      title: string;
    };
    titleThreeLine: {
      titleThreeLine: string;
    };
    image: {
      gatsbyImageData: string;
    };
    pointNumber: string;
  };
};

const AboutItem: React.FC<AboutItemProps> = ({ feature }) => {
  const imageNode: any = feature.image;
  const image = getImage(imageNode);

  return (
    <div
      className="aboutItem__container"
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-duration="500"
    >
      <div className="point__circle point__circle-aboutItem">
        <span className="text-center">
          <span className="point__text">POINT</span>
          <br />
          <span className="point__number">{feature.pointNumber}</span>
        </span>
      </div>
      <div className="aboutItem__box">
        <div className="aboutItem__title block md:w-full">
          {feature.titleThreeLine.titleThreeLine}
        </div>
        <div className="w-4/12 md:w-full h-full text-center">
          <GatsbyImage
            image={image}
            alt={feature.title.title}
            className="object-scale-down h-24 xl:h-32 2xl:h-40"
            loading="lazy"
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutItem;
