import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Symbol from "../assets/images/symbol.inline.svg";
import { SectionProps } from "./section";

type AppealPointsProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.AppealPointsQuery>;

const AppealPoints: React.FC<AppealPointsProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.AppealPointsQuery>(graphql`
    query AppealPoints {
      allContentfulLayoutAppealPoints {
        edges {
          node {
            id
            heading {
              heading
            }
            appealText1 {
              appealText1
            }
            appealText1Pc {
              appealText1Pc
            }
            appealText2 {
              appealText2
            }
            appealText3 {
              appealText3
            }
            caution {
              caution
            }
            pc {
              gatsbyImageData(quality: 100, placeholder: NONE)
            }
            appealImg1 {
              gatsbyImageData(quality: 100, placeholder: NONE, width: 80)
            }
            appealImg2 {
              gatsbyImageData(quality: 100, placeholder: NONE, width: 80)
            }
            appealImg3 {
              gatsbyImageData(quality: 100, placeholder: NONE, width: 80)
            }
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutAppealPoints.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const pcImageNode: any = content.node.pc;
  const pcImage = getImage(pcImageNode);

  const appealImg1Node: any = content.node.appealImg1;
  const appealImg1 = getImage(appealImg1Node);

  const appealImg2Node: any = content.node.appealImg2;
  const appealImg2 = getImage(appealImg2Node);

  const appealImg3Node: any = content.node.appealImg3;
  const appealImg3 = getImage(appealImg3Node);

  return (
    <section
      id="appealPoints"
      className="container section mx-auto pt-48 md:pt-0"
    >
      <div className="section appeal-points__section">
        <div className="appeal-points__top-container">
          <div
            className="section__title-container appeal-points__title-container"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            <h2 className="section__title appeal-points__title justify-center whitespace-pre">
              {content.node.heading.heading}
            </h2>
            <Symbol
              className="section__symbol"
              alt="医療機器管理システム HITOTSU ロゴ横棒"
              loading="lazy"
            />
          </div>
          <div>
            <GatsbyImage
              image={pcImage}
              alt={
                "医療機器管理システム HITOTSU 時代の先を行くユーザー体験をご提供します"
              }
              className="appeal-points__img"
              loading="lazy"
              objectFit="cover"
              style={{ display: "block" }}
            />
          </div>
        </div>
        <div className="appeal-points__bottom-container">
          <div className="appeal-point1__container">
            <GatsbyImage
              image={appealImg1}
              alt={content.node.appealText1.appealText1}
              className="appeal-point1__img"
              loading="lazy"
              objectFit="cover"
            />
            <div className="appeal-point1__text md:hidden">
              {content.node.appealText1.appealText1}
            </div>
            <div className="appeal-point1__text hidden md:block">
              {content.node.appealText1Pc.appealText1Pc}
            </div>
            <div className="appeal-point1__caution">
              {content.node.caution.caution}
            </div>
          </div>
          <div className="flex md:hidden">
            <div className="appeal-point2__container flex">
              <GatsbyImage
                image={appealImg2}
                alt={content.node.appealText2.appealText2}
                className="appeal-point2__img"
                loading="lazy"
                objectFit="cover"
              />
              <div className="appeal-point2__text">
                {content.node.appealText2.appealText2}
              </div>
            </div>
            <div className="appeal-point3__container flex">
              <GatsbyImage
                image={appealImg3}
                alt={content.node.appealText3.appealText3}
                className="appeal-point3__img"
                loading="lazy"
                objectFit="cover"
              />
              <div className="appeal-point3__text">
                {content.node.appealText3.appealText3}
              </div>
            </div>
          </div>
          <div className="appeal-point2__container hidden md:flex">
            <GatsbyImage
              image={appealImg2}
              alt={content.node.appealText2.appealText2}
              className="appeal-point2__img"
              loading="lazy"
              objectFit="cover"
            />
            <div className="appeal-point2__text">
              {content.node.appealText2.appealText2}
            </div>
          </div>
          <div className="appeal-point3__container hidden md:flex">
            <GatsbyImage
              image={appealImg3}
              alt={content.node.appealText3.appealText3}
              className="appeal-point3__img"
              loading="lazy"
              objectFit="cover"
            />
            <div className="appeal-point3__text">
              {content.node.appealText3.appealText3}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppealPoints;
