import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import ContactFormRole from "../components/contactFormRoles";
import ContactFormInterest from "../components/contactFormInterests";
import { SectionProps } from "./section";
import ContactFormSource from "../components/contactFormSources";

type NetlifyFormProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.NetlifyFormQuery>;

const NetlifyForm: React.FC<NetlifyFormProps> = () => {
  const data = useStaticQuery<GatsbyTypes.NetlifyFormQuery>(graphql`
    query NetlifyForm {
      contentfulLayoutContactForm {
        id
        heading
        subheading
        roleDescription
        roles {
          id
          title
        }
        interestDescription
        interests {
          id
          interest
        }
        message {
          id
          message
        }
      }
    }
  `);

  const content = data.contentfulLayoutContactForm;

  return (
    <section id="netlifyForm">
      <form
        name="contact"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        hidden
        action="/thank-you"
      >
        <input
          type="text"
          name="hospital_name"
          placeholder="医療機関名"
          className="modal__form"
        />
        <input
          type="text"
          name="first_name"
          placeholder="姓*"
          className="modal__form flex-1 mr-1"
        />
        <input
          type="text"
          name="last_name"
          placeholder="名*"
          className="modal__form flex-1 ml-1"
        />

        <div className="modal__form">
          <select id="role" name="role">
            <option
              value=""
              className="modal__select-placeholder"
              disabled
              selected
              hidden
            >
              役職*
            </option>
            {content.roles.map(role => (
              <ContactFormRole role={role} />
            ))}
          </select>
        </div>

        <input
          type="email"
          name="email"
          placeholder="メールアドレス"
          className="modal__form"
        />
        <input
          type="phone"
          name="phone"
          placeholder="電話番号"
          className="modal__form"
        />

        <div className="modal__form">
          {content.interests.map((interest, index) => (
            <ContactFormInterest interest={interest} index={index} />
          ))}
          <label>
            <input
              type="checkbox"
              name="interest[]"
              value="その他"
              className="modal__checkbox"
            />
            その他
          </label>
          <br />
        </div>

        <textarea
          name="free-text"
          id="free-text"
          cols={30}
          rows={5}
          placeholder="上記以外で詳しく問い合わせしたい内容がございましたら、ご記入ください。"
          className="modal__textarea"
        />

        <label htmlFor="interest" className="modal__form-label">
          <span className="modal__form-optional-text">任意</span>
          HITOTSUをお知りになったきっかけ
        </label>
        <div className="modal__form-checkbox">
          <p className="modal__interest-description">
            {content.interestDescription}
          </p>
          <br />
        </div>
      </form>
    </section>
  );
};

export default NetlifyForm;
