import React from "react";
import { getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import mp40101 from "../assets/videos/function01-01.mp4";
import webm0101 from "../assets/videos/function01-01.webm";
import mp40102 from "../assets/videos/function01-02.mp4";
import webm0102 from "../assets/videos/function01-02.webm";
import mp40201 from "../assets/videos/function02-01.mp4";
import webm0201 from "../assets/videos/function02-01.webm";
import mp40202 from "../assets/videos/function02-02.mp4";
import webm0202 from "../assets/videos/function02-02.webm";
import mp40203 from "../assets/videos/function02-03.mp4";
import webm0203 from "../assets/videos/function02-03.webm";
import mp40301 from "../assets/videos/function03-01.mp4";
import webm0301 from "../assets/videos/function03-01.webm";
import mp40302 from "../assets/videos/function03-02.mp4";
import webm0302 from "../assets/videos/function03-02.webm";
import LazyLoad from "react-lazyload";

type FunctionItemProps = {
  functionItem: {
    heading: {
      heading: {
        heading: string;
      };
    };
    subheading: string;
    title: {
      title: string;
    };
    titleForMobile: {
      titleForMobile: string;
    };
    subtitle: {
      subtitle: string;
    };
    subtitleForMobile: {
      subtitleForMobile: string;
    };
    image: {
      gatsbyImageData;
    };
    categoryNumber: number;
    itemNumber: number;
  };
  index: number;
};

const mp4s = [mp40101, mp40102, mp40201, mp40202, mp40203, mp40301, mp40302];
const webms = [
  webm0101,
  webm0102,
  webm0201,
  webm0202,
  webm0203,
  webm0301,
  webm0302,
];

const customStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "10px",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxHeight: "calc(100vh - 2rem)",
    overflowY: "auto",
  },
};

const FunctionItem: React.FC<FunctionItemProps> = ({ functionItem, index }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={`functionItem__content functionItem_content-${index}`}>
      <div
        id={`functionItem__content-container-${index}`}
        className="functionItem__content-container"
      >
        <div
          id={`functionItem__title-subtitle-container-${index}`}
          className={`functionItem__title-subtitle-container`}
        >
          <strong
            id={`functionItem__title-${index}`}
            className={`functionItem__title`}
          >
            {typeof window !== "undefined" && window.innerWidth < 768
              ? functionItem.titleForMobile.titleForMobile
              : functionItem.title.title}
          </strong>
        </div>
        <div className="flex-1">
          <LazyLoad height={200} offset={100} once>
            <video
              className={`functionItem__image ${
                index === 4 && "functionItem__tablet-video"
              }`}
              onClick={openModal}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={webms[index]} type="video/webm" />
              <source src={mp4s[index]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </LazyLoad>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="HITOTSU機能動画モーダル"
          >
            <LazyLoad height={200} once>
              <video
                className={`functionItem__image-expand ${
                  index === 4 && "functionItem__tablet-video-expand"
                }`}
                onClick={closeModal}
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={webms[index]} type="video/webm" />
                <source src={mp4s[index]} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </LazyLoad>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default FunctionItem;
