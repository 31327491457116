import React from "react";
import Hero from "./hero";
import About from "./about";
import AppealPoints from "./appealPoints";
import Problem from "./problem";
import Solutions from "./solutions";
import Functions from "./functions";
import Testimonials from "./testimonials";
import Support from "./support";
import Disaster from "./disaster";
import Recruitment from "./recruitment";
import IntroductionFlow from "./introductionFlow";
import ReleaseNotes from "./releaseNotes";
import Price from "./price";
import News from "./news";
import PageNotFound from "./pageNotFound";
import ContactForm from "./netlifyForm";

// Dynamically import or require sections inside the section folder
const components = {
  Hero,
  About,
  AppealPoints,
  // Problem,
  // Solutions,
  Functions,
  Testimonials,
  // Support,
  // Disaster,
  // Recruitment,
  IntroductionFlow,
  ReleaseNotes,
  Price,
  News,
  PageNotFound,
  ContactForm,
};

export type SectionProps = {
  contentModuleId: string;
  type: string;
};

const Section: React.FC<SectionProps> = ({ contentModuleId, type }) => {
  
  const component = type.split("Layout")[1];

  if (typeof components[component] === "undefined") {
    return <></>;
  }

  return React.createElement(
    components[component],
    {
      contentModuleId: contentModuleId,
    },
    null
  );
};

export default Section;
