/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, PageProps } from "gatsby";

type SEOProps = {
  title: string;
  description: string;
};

const SEO: React.FC<SEOProps> = ({ description, title }) => {
  const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
            image
            domain
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaImage = `//${site.siteMetadata.domain}/${site.siteMetadata.image}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s `}
      meta={[
        {
          name: `google-site-verification`,
          content: `eFoO_mqm7L4BUo7Aa2l8L6wY0KgZXcxysB9yh7CSomU`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https:${metaImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `http:${metaImage}`,
        },
      ]}
    >
      {/* comment out Hotjar Tracking Code because we now use clarity*/}
      {/* <script>
        {`(function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 2709442, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`}
      </script> */}
    </Helmet>
  );
};

export default SEO;
