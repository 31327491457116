import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Modal from "../components/contactForm";
import Glider from "glider-js";
import Symbol from "../assets/images/symbol.inline.svg";
import FunctionItem from "../components/functionItem";
import { SectionProps } from "./section";

type FunctionsProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.FunctionsQuery>;

const Functions: React.FC<FunctionsProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.FunctionsQuery>(graphql`
    query Functions {
      allContentfulLayoutFunctions {
        edges {
          node {
            id
            heading
            functionItems {
              heading {
                heading {
                  heading
                }
              }
              subheading
              title {
                title
              }
              titleForMobile {
                titleForMobile
              }
              subtitle {
                subtitle
              }
              subtitleForMobile {
                subtitleForMobile
              }
              image {
                gatsbyImageData(quality: 25, placeholder: NONE, height: 350)
              }
              categoryNumber
              itemNumber
            }
            callToAction
            ctaUrl
            developmentPlanPc
            developmentPlanSp {
              developmentPlanSp
            }
            developmentPlan1
            developmentPlan2
            developmentPlan3
            developmentPlan4
            developmentPlan5
            developmentPlanMonth3
            developmentPlanMonth4
            developmentPlanMonth5
          }
        }
      }
      allContentfulFunction(sort: { fields: [number], order: ASC }) {
        edges {
          node {
            id
            heading {
              heading
            }
            subheading {
              id
              subheading
              categoryNumber
              itemNumber
            }
            number
            createdAt
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutFunctions.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const initSlider = () => {
    const glider = new Glider(document.querySelector(".glider"), {
      slidesToShow: 1,
      scrollLock: true,
      rewind: true,
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next",
      },
    });
  };

  const [currentGlider, setCurrentGlider] = useState(1);

  const addSubheadingBGColor = (parent, child) => {
    document
      .querySelectorAll(".glider-subheading-container")
      [parent].children[child].classList.add("glider-subheading-active-color");
  };

  const addHeadingBGColor = parent => {
    document
      .querySelectorAll(".glider-heading")
      [parent].classList.add("glider-heading-active-color");
  };

  const removeHeadingBGColor = parent => {
    document
      .querySelectorAll(".glider-heading")
      [parent].classList.remove("glider-heading-active-color");
  };

  const addSubheadingContainerHidden = (slide1, slide2) => {
    document
      .querySelectorAll(".glider-subheading-container")
      [slide1].classList.add("hidden");
    document
      .querySelectorAll(".glider-subheading-container")
      [slide2].classList.add("hidden");
  };

  const removeSubheadingContainerHidden = slide1 => {
    document
      .querySelectorAll(".glider-subheading-container")
      [slide1].classList.remove("hidden");
  };

  const setGlider = () => {
    document
      .querySelector(".glider")
      .addEventListener("glider-slide-visible", event => {
        if (event.detail.slide === 0 || event.detail.slide === 1) {
          setCurrentGlider(1);
          [].map.call(
            document.querySelectorAll(".glider-subheading"),
            subheading =>
              subheading.classList.remove("glider-subheading-active-color")
          );
          addSubheadingBGColor(0, event.detail.slide);

          removeHeadingBGColor(1);
          removeHeadingBGColor(2);
          addHeadingBGColor(0);

          addSubheadingContainerHidden(1, 2);
          removeSubheadingContainerHidden(0);
        } else if (
          event.detail.slide === 2 ||
          event.detail.slide === 3 ||
          event.detail.slide === 4
          // event.detail.slide === 5
        ) {
          setCurrentGlider(2);
          [].map.call(
            document.querySelectorAll(".glider-subheading"),
            subheading =>
              subheading.classList.remove("glider-subheading-active-color")
          );
          addSubheadingBGColor(1, event.detail.slide - 2);

          removeHeadingBGColor(0);
          removeHeadingBGColor(2);
          addHeadingBGColor(1);

          addSubheadingContainerHidden(0, 2);
          removeSubheadingContainerHidden(1);
        } else {
          setCurrentGlider(3);
          [].map.call(
            document.querySelectorAll(".glider-subheading"),
            subheading =>
              subheading.classList.remove("glider-subheading-active-color")
          );
          addSubheadingBGColor(2, event.detail.slide - 5);

          removeHeadingBGColor(0);
          removeHeadingBGColor(1);
          addHeadingBGColor(2);

          addSubheadingContainerHidden(0, 1);
          removeSubheadingContainerHidden(2);
        }
      });
  };

  const scrollItem = slide => {
    const glider = new Glider(document.querySelector(".glider"), {
      slidesToShow: 1,
      scrollLock: true,
      rewind: true,
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next",
      },
    });

    glider.scrollItem(slide);
  };

  const categoryToSlide = [0, 2, 5];

  useEffect(() => {
    initSlider();
    setGlider();
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section
      id="functions"
      className="functions section mx-auto bg-lightGray"
      style={{ maxWidth: "100%" }}
    >
      <div className="w-full text-center function-container">
        <div
          className="section__title-container"
          data-sal="fade"
          data-sal-easing="ease-in-cubic"
        >
          <h2 className="section__title function__title">
            {content.node.heading}
          </h2>
          <Symbol
            className="section__symbol"
            alt="医療機器管理システム HITOTSU ロゴ横棒"
            loading="lazy"
          />
        </div>

        <div>
          <div className="function__heading-container">
            {data.allContentfulFunction.edges.map((edge, index) => (
              <div
                className={`glider-heading flex items-center bg-secondary text-white whitespace-pre-wrap md:whitespace-nowrap ${
                  index === 0 ? "glider-heading-active-color" : ""
                }`}
                onClick={() => scrollItem(categoryToSlide[index])}
              >
                <div>
                  {typeof window !== "undefined" && window.innerWidth < 768
                    ? edge.node.heading.heading
                    : edge.node.heading.heading.replace("\n", "")}
                </div>
              </div>
            ))}
          </div>

          {content.node.functionItems.length > 0 && (
            <div className="function__slider">
              <div className="glider-contain">
                <div className="glider-subheadings-container">
                  {data.allContentfulFunction.edges.map((edge, index) => (
                    <div
                      className={`glider-subheading-container grid ${
                        index === 0 ? "" : "hidden"
                      }`}
                    >
                      {edge.node.subheading.map((subheading, index) =>
                        subheading.categoryNumber === currentGlider ? (
                          <div
                            id={subheading.id}
                            key={subheading.id}
                            className={`glider-subheading bg-white text-secondary ${
                              index === 0
                                ? "glider-subheading-active-color"
                                : ""
                            }`}
                            onClick={() => scrollItem(subheading.itemNumber)}
                          >
                            {subheading.subheading}
                          </div>
                        ) : null
                      )}
                    </div>
                  ))}
                </div>
                <div className="glider p-1">
                  {content.node.functionItems.map((functionItem, index) => (
                    <FunctionItem functionItem={functionItem} index={index} />
                  ))}
                </div>
              </div>
              <button className="glider-prev ml-4" type="button">
                ＜
              </button>
              <button className="glider-next mr-4" type="button">
                ＞
              </button>
              <div className="glider__dots" />
            </div>
          )}
        </div>
        <div className="function__development-container">
          <div className="hidden md:block text-xs xl:text-base 2xl:text-xl">
            {content.node.developmentPlanPc}
          </div>
          <div className="function__development-plan-sp text-xs md:hidden whitespace-pre">
            {content.node.developmentPlanSp.developmentPlanSp}
          </div>
          {/* for sp site */}
          <div className="function__plan-item-container md:hidden flex flex-col text-xs">
            <div className="flex justify-around">
              <div className="function__plan">
                {content.node.developmentPlan1}
              </div>
              <div className="function__plan">
                {content.node.developmentPlan2}
              </div>
            </div>
            <div className="flex justify-around mt-8">
              <div>
                <div className="function__plan">
                  {content.node.developmentPlan3}
                </div>
              </div>
              <div>
                <div className="function__plan">
                  {content.node.developmentPlan4}
                </div>
              </div>
              <div>
                <div className="function__plan">
                  {content.node.developmentPlan5}
                </div>
              </div>
            </div>
          </div>
          {/* for pc site */}
          <div className="hidden function__plan-item-container md:flex justify-around md:text-xs xl:mb-5 xl:text-lg">
            <div className="function__plan">
              {content.node.developmentPlan1}
            </div>
            <div className="function__plan">
              {content.node.developmentPlan2}
            </div>
            <div>
              <div className="function__plan">
                {content.node.developmentPlan3}
              </div>
            </div>
            <div>
              <div className="function__plan">
                {content.node.developmentPlan4}
              </div>
            </div>
            <div>
              <div className="function__plan">
                {content.node.developmentPlan5}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10 md:mt-16 2xl:mt-20">
          <button
            id="modal__btn-interest1"
            className="btn btn--primary rounded-full"
            type="button"
            data-sal="fade"
            onClick={openModal}
          >
            {content.node.callToAction}
          </button>
          <Modal
            modalIsOpen={modalIsOpen}
            closeModal={() => {
              closeModal();
            }}
            interestNum={"interest2"}
          />
        </div>
      </div>
    </section>
  );
};

export default Functions;
