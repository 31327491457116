import React from "react";
import { useStaticQuery, graphql, PageProps, Link } from "gatsby";
import Symbol from "../assets/images/symbol.inline.svg";
import { SectionProps } from "./section";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export type ReleaseNotesProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.ReleaseNotesQuery>;

const ReleaseNotes: React.FC<ReleaseNotesProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.ReleaseNotesQuery>(
    graphql`
      query ReleaseNotes {
        allContentfulLayoutReleaseNotes {
          edges {
            node {
              id
              heading
              callToAction
              callToActionUrl
              releaseNoteItems {
                id
                title
                date(formatString: "YYYY.MM.DD")
                url
                thumbnail {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    `
  );

  const content = data.allContentfulLayoutReleaseNotes.edges.find(
    edge => edge.node.id === contentModuleId
  );

  return (
    <section id="release-notes" className="release-notes">
      <div className="section px-0 pt-0">
        <div className="releaseNotes__content container">
          <div
            className="section__title-container"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            <h2 className="section__title releaseNotes__title">
              {content.node.heading}
            </h2>
            <Symbol
              className="section__symbol"
              alt="医療機器管理システム HITOTSU ロゴ横棒"
              loading="lazy"
            />
          </div>
          {content.node.releaseNoteItems.length > 0 && (
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={30}
              slidesPerView={3}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              className="releaseNotes__swiper"
              breakpoints={{
                1: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {content.node.releaseNoteItems.map((releaseNotesItem, index) => {
                const thumbnailNode: any = releaseNotesItem.thumbnail;
                const thumbnail = getImage(thumbnailNode);
                return (
                  <SwiperSlide id={`slide_${index}`} key={releaseNotesItem.id}>
                    <Link
                      to={releaseNotesItem.url}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                    >
                      <div className="releaseNoteItem__container">
                        <GatsbyImage
                          image={thumbnail}
                          alt={releaseNotesItem.title}
                          loading="lazy"
                          objectFit="contain"
                          className="releaseNoteItem__thumbnail"
                        />
                        <div className="releaseNoteItem__text-container">
                          <div>{releaseNotesItem.date}</div>
                          <div className="releaseNoteItem__title">
                            {releaseNotesItem.title}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          <div className="flex justify-center mt-8 md:mt-12 2xl:mt-16">
            <a
              href={content.node.callToActionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="btn btn--primary rounded-full"
                data-sal="fade"
              >
                {content.node.callToAction}
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReleaseNotes;
