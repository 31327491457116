import React from "react";

export const CommonDescription: React.FC = () => {
  return (
    <div
      className="pricing__common-container"
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-duration="500"
    >
      <div className="pricing__common-title">
        <p>全プラン共通</p>
      </div>

      <div
        className="pricing__common-description_container"
        style={{ textAlign: "center" }}
      >
        <div className="pricing__common-initial_cost">初期導入費用 ¥0</div>
        <div className="pricing__common-divider" />
        <div className="pricing__common-clause">
          登録できる医療機器数：
          <wbr />
          制限なし
        </div>
      </div>
    </div>
  );
};
