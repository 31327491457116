import React from "react";
import { useStaticQuery, graphql, Link, PageProps } from "gatsby";
import { SectionProps } from "./section";

type PageNotFoundProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.PageNotFoundQuery>;

const PageNotFound: React.FC<PageNotFoundProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.PageNotFoundQuery>(graphql`
    query PageNotFound {
      allContentfulLayoutPageNotFound {
        edges {
          node {
            id
            heading
            description {
              description
            }
            buttonText
            buttonUrl
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutPageNotFound.edges.find(
    edge => edge.node.id === contentModuleId
  );

  return (
    <section className="page-not-found container section mx-auto text-center py-24">
      <div className="flex flex-col justify-center mx-auto">
        <h2 className="font-bold text-5xl mb-12">{content.node.heading}</h2>
        <div className="page-not-found__image-wrap mb-12"></div>
        <p className="mb-12 text-sm whitespace-pre-wrap">
          {content.node.description.description}
        </p>
        <div>
          <Link
            to={content.node.buttonUrl}
            className="btn btn--primary rounded-full text-center w-52"
          >
            {content.node.buttonText}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
