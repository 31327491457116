import React, { CSSProperties } from "react";

type Props = {
  numberOfBedsText: string;
  suffixNumberOfBedsText?: string;
  price: number | React.ReactNode;
  numberOfUsers?: number;
};

function addComma(value: number) {
  if (value != 0 && !value) {
    return "";
  }
  const onlyNums = String(value).replace(/[^\d]/g, "");
  return onlyNums.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export const PriceCard: React.FC<Props> = ({
  numberOfBedsText,
  suffixNumberOfBedsText,
  price,
  numberOfUsers,
}) => {
  return (
    <div
      className="price__card"
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-duration="500"
    >
      <div className="price__card--container">
        <div className="price__card--bed_number">
          <p className="price__card--bed_number_title">病床数</p>
          <p className="price__card--bed_number_content">
            {numberOfBedsText}
            <span className="price__card--bed_number_text">
              床{suffixNumberOfBedsText ?? ""}
            </span>
          </p>
        </div>
        <div className="price__card--price">
          {Number.isNaN(parseInt(price as string)) ? (
            <p className="price__card--ask-us-text">{price}</p>
          ) : (
            <div className="price__card--right-side-container">
              <p>
                ¥{addComma(price as number)}
                <span className="price__card--month-text">/月</span>
              </p>
              <p className="price__card--number-of-user">
                <span className="price__card--number-of-user-text">
                  利用可能な
                  <br className="lg:hidden" />
                  ユーザー数
                </span>
                <span>{numberOfUsers}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
