import React, { useState } from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Symbol from "../assets/images/symbol.inline.svg";
import { SectionProps } from "./section";
import { PriceCard } from "../components/Pricing/PriceCard";
import Modal from "../components/contactForm";
import { CommonDescription } from "../components/Pricing/CommonDescription";

type PriceProps = Partial<SectionProps> & PageProps<GatsbyTypes.PriceQuery>;

const Price: React.FC<PriceProps> = ({ contentModuleId }) => {
  const data = useStaticQuery<GatsbyTypes.PriceQuery>(graphql`
    query Price {
      allContentfulLayoutPrice {
        edges {
          node {
            id
            heading
            subheading {
              subheading
            }
            option1BedNumber
            option1Price
            option2BedNumber
            option2Price
            option3BedNumber
            option3Price
            option4BedNumber
            option4Price1Line
            option4Price2Lines {
              option4Price2Lines
            }
            sharedPlanTitle
            sharedPlan1
            sharedPlan2
            caution1
            caution2
            caution3
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutPrice.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section id="price" className="container section mx-auto">
      <div className="w-full">
        <div>
          <div
            className="section__title-container"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
            style={{ marginBottom: 40 }}
          >
            <h2 className="section__title price__title justify-center whitespace-pre">
              {content.node.heading}
            </h2>
            <Symbol
              className="section__symbol"
              alt="医療機器管理システム HITOTSU ロゴ横棒"
              loading="lazy"
            />
          </div>
        </div>
        <div
          className="price__subheading"
          data-sal="slide-up"
          data-sal-easing="ease-in-cubic"
          data-sal-delay="100"
        >
          {content.node.subheading.subheading}
        </div>
        <div>
          <div
            className="hidden whitespace-pre price_card__container"
            style={{
              marginBottom: 40,
            }}
          >
            <div className="price__card--wrapper">
              <PriceCard
                numberOfBedsText={"20-99"}
                price={30000}
                numberOfUsers={10}
              />
            </div>
            <div className="price__card--wrapper">
              <PriceCard
                numberOfBedsText={"100-299"}
                price={50000}
                numberOfUsers={20}
              />
            </div>
            <div className="price__card--wrapper">
              <PriceCard
                numberOfBedsText={"300-499"}
                price={70000}
                numberOfUsers={30}
              />
            </div>
            <div className="price__card--wrapper">
              <PriceCard
                numberOfBedsText={"500"}
                suffixNumberOfBedsText={"以上"}
                price={
                  <>
                    お問い合わせ
                    <wbr />
                    ください
                  </>
                }
              />
            </div>
          </div>
          <div style={{ marginBottom: 16 }}>
            <CommonDescription />
          </div>
          <div>
            <p
              style={{
                fontWeight: 700,
                fontSize: 16,
                lineHeight: "22px",
                color: "#0E3870",
              }}
            >
              {content.node.caution1}
              <br />
              {content.node.caution2}
              <br />
              {content.node.caution3}
            </p>
          </div>
          <div style={{ marginTop: 40, textAlign: "center" }}>
            <button
              id="modal__btn-interest1"
              className="btn btn--primary rounded-full sal-animate"
              type="button"
              data-sal="fade"
              onClick={openModal}
            >
              料金プランについて問い合わせる
            </button>
            <Modal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              interestNum={"interest2"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Price;
