import React, { useState } from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Symbol from "../assets/images/symbol.inline.svg";
import { SectionProps } from "./section";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "../components/contactForm";

export type IntroductionFlowProps = Partial<SectionProps> &
  PageProps<GatsbyTypes.IntroductionFlowQuery>;

const IntroductionFlow: React.FC<IntroductionFlowProps> = ({
  contentModuleId,
}) => {
  const data = useStaticQuery<GatsbyTypes.IntroductionFlowQuery>(
    graphql`
      query IntroductionFlow {
        allContentfulLayoutIntroductionFlow {
          edges {
            node {
              id
              heading
              subheading {
                subheading
              }
              caution
              ifContinue
              callToAction1
              callToAction2
              introductionFlowItems {
                id
                stepNumber
                title {
                  title
                }
                icon {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
                description {
                  description
                }
                callToAction
              }
            }
          }
        }
      }
    `
  );

  const content = data.allContentfulLayoutIntroductionFlow.edges.find(
    edge => edge.node.id === contentModuleId
  );

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section id="introduction-flow" className="introduction-flow">
      <div className="section mx-auto justify-center">
        <div className="introductionFlow__content container">
          <div
            className="section__title-container"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            <h2 className="section__title introduction-flow__title">
              {content.node.heading}
            </h2>
            <Symbol
              className="section__symbol"
              alt="医療機器管理システム HITOTSU ロゴ横棒"
              loading="lazy"
            />
          </div>
          <div className="introduction-flow__subheading">
            {content.node.subheading.subheading}
          </div>
          {content.node.introductionFlowItems.length > 0 && (
            <div className="flex flex-col m-0 text-xs lg:text-sm xl:text-base 2xl:text-xl">
              {content.node.introductionFlowItems.map(
                (introductionFlowItem, index) => {
                  const iconNode: any = introductionFlowItem.icon;
                  const icon = getImage(iconNode);
                  return (
                    <div
                      className={`introduction-flow-item__container mb-5 xl:mb-8 ${
                        index === 3 && "relative mb-24 xl:mb-32"
                      }`}
                    >
                      <div className="introduction-flow-item__inner-container">
                        <div className="introduction-flow-item__step-container">
                          STEP
                          <span className="introduction-flow-item__step-number">
                            {introductionFlowItem.stepNumber}
                          </span>
                        </div>
                        <div
                          className={`flex justify-center md:w-11/12 ${
                            index === 1 && "flex-col"
                          }`}
                        >
                          <div className="introduction-flow-item__right-container">
                            <div className="flex items-center">
                              <GatsbyImage
                                image={icon}
                                alt="医療機器管理システム HITOTSU 導入フロー 画像"
                                className="introduction-flow__img"
                                loading="lazy"
                                objectFit="cover"
                              />
                              <div className="pl-1 whitespace-pre xl:pl-4">
                                {introductionFlowItem.title.title}
                              </div>
                            </div>
                            {(index === 0 || index === 1) && (
                              <div className="hidden md:flex justify-center">
                                <button
                                  id={`modal__btn-interest${index}`}
                                  className="btn btn--primary rounded-full introduction-flow__btn"
                                  type="button"
                                  data-sal="fade"
                                  onClick={openModal}
                                >
                                  {introductionFlowItem.callToAction}
                                </button>
                                <Modal
                                  modalIsOpen={modalIsOpen}
                                  closeModal={() => {
                                    closeModal();
                                  }}
                                  interestNum={`interest${index}`}
                                />
                              </div>
                            )}
                          </div>
                          {introductionFlowItem.description !== null && (
                            <div className="hidden md:block lg:pl-3">
                              <hr />
                              <div className="introduction-flow__description-text">
                                {introductionFlowItem.description.description}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {index === 3 && (
                        <div className="introduction-flow__caution-container">
                          <div className="introduction-flow__caution-text">
                            {content.node.caution}
                          </div>
                          <div className="introduction-flow__if-continue">
                            {content.node.ifContinue}
                          </div>
                        </div>
                      )}
                    </div>
                    // <IntroductionFlowItem
                    //   introductionFlowItem={introductionFlowItem}
                    // />
                  );
                }
              )}
            </div>
          )}
          <div className="md:hidden flex justify-center">
            <button
              id="modal__btn-interest1"
              className="btn btn--primary rounded-full w-52"
              type="button"
              data-sal="fade"
              onClick={openModal}
            >
              資料を請求する
            </button>
            <Modal
              modalIsOpen={modalIsOpen}
              closeModal={() => {
                closeModal();
              }}
              interestNum={"interest1"}
            />
          </div>
          <div className="md:hidden flex justify-center mt-5">
            <button
              id="modal__btn-interest1"
              className="btn btn--primary rounded-full w-52"
              type="button"
              data-sal="fade"
              onClick={openModal}
            >
              フリートライアルを申込む
            </button>
            <Modal
              modalIsOpen={modalIsOpen}
              closeModal={() => {
                closeModal();
              }}
              interestNum={"interest1"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroductionFlow;
